import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ProjectClient, ProjectDto, ProjectTaskDto } from "../util/apiClient";

export const projectQueryKeys = {
  getAll: ["ProjectGetAll"],
  getAssignedToUser: ["ProjectGetAssignedToUser"],
  getAssignedToUserWithId: (userId: number) => [
    ...projectQueryKeys.getAssignedToUser,
    userId,
  ],
} as const;

const client = new ProjectClient();

export interface IProjectRenameProjectMutationParameters {
  projectId: number;
  projectName: string;
}

export interface IProjectRenameTaskMutationParameters {
  taskId: number;
  taskName: string;
}

export interface IProjectCreateTaskMutationParameters {
  projectId: number;
  task: ProjectTaskDto;
}

export interface IProjectSetUserAssignedTasksMutationParameters {
  userId: number;
  tasks: Set<number>;
}

export const useProjectGetAllQuery = () =>
  useQuery({
    queryKey: projectQueryKeys.getAll,
    queryFn: async () => client.getAll(),
  });

export const useProjectCreateProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (project: ProjectDto) => client.createProject(project),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      queryClient.invalidateQueries({
        queryKey: projectQueryKeys.getAll,
      }),
  });
};

export const useProjectDeleteProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (projectId: number) => client.deleteProject(projectId),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: projectQueryKeys.getAll,
        }),
        queryClient.invalidateQueries({
          queryKey: projectQueryKeys.getAssignedToUser,
        }),
      ]),
  });
};

export const useProjectRenameProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (parameters: IProjectRenameProjectMutationParameters) =>
      client.renameProject(parameters.projectId, parameters.projectName),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: projectQueryKeys.getAll,
        }),
        queryClient.invalidateQueries({
          queryKey: projectQueryKeys.getAssignedToUser,
        }),
      ]),
  });
};

export const useProjectGetAssignedToUserQuery = (userId: number) =>
  useQuery({
    queryKey: projectQueryKeys.getAssignedToUserWithId(userId),
    queryFn: async () => client.getAssignedToUser(userId),
  });

export const useProjectSetUserAssignedTasksMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      parameters: IProjectSetUserAssignedTasksMutationParameters,
    ) => client.setUserAssignedTasks(parameters.userId, [...parameters.tasks]),
    onError: async (error) => {
      throw error;
    },
    onSettled: async (_data, _error, variables) =>
      queryClient.invalidateQueries({
        queryKey: projectQueryKeys.getAssignedToUserWithId(variables.userId),
      }),
  });
};

export const useProjectCreateTaskMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (parameters: IProjectCreateTaskMutationParameters) =>
      client.createTask(parameters.projectId, parameters.task),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      queryClient.invalidateQueries({
        queryKey: projectQueryKeys.getAll,
      }),
  });
};

export const useProjectDeleteTaskMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => client.deleteTask(id),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: projectQueryKeys.getAll,
        }),
        queryClient.invalidateQueries({
          queryKey: projectQueryKeys.getAssignedToUser,
        }),
      ]),
  });
};

export const useProjectRenameTaskMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (parameters: IProjectRenameTaskMutationParameters) =>
      client.renameTask(parameters.taskId, parameters.taskName),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: projectQueryKeys.getAll,
        }),
        queryClient.invalidateQueries({
          queryKey: projectQueryKeys.getAssignedToUser,
        }),
      ]),
  });
};
