import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  Cog6ToothIcon,
  ChartBarSquareIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useUserUserInfoQuery } from "../../hooks/userQueries";

export type NavigationItem = {
  name: string;
  id: string;
  icon: typeof HomeIcon;
  href: string;
  current?: boolean;
};

export const useNavigationItems = (): NavigationItem[] | undefined => {
  const location = useLocation();
  const { t } = useTranslation();
  const userUserInfoQuery = useUserUserInfoQuery();

  const adminItems = [
    {
      name: t("navigation.items.users"),
      id: "menu-admin-users",
      icon: UsersIcon,
      href: "/users",
    },
    {
      name: t("navigation.items.projects"),
      id: "menu-admin-projects",
      icon: FolderIcon,
      href: "/projects",
    },
    {
      name: t("navigation.items.reports"),
      id: "menu-admin-reports",
      icon: ChartBarIcon,
      href: "/reports",
    },
    {
      name: t("navigation.items.organization"),
      id: "menu-admin-organization",
      icon: Cog6ToothIcon,
      href: "/organization",
    },
  ];

  const userItems = [
    {
      name: t("navigation.items.dashboard"),
      id: "menu-dashboard",
      icon: ChartBarSquareIcon,
      href: "/",
    },
    {
      name: t("navigation.items.entries"),
      id: "menu-entries",
      icon: CalendarIcon,
      href: "/entry",
    },
  ];

  if (userUserInfoQuery.data) {
    const activeItems = userUserInfoQuery.data.admin
      ? userItems.concat(adminItems)
      : userItems;

    return activeItems.map((x) => {
      const current =
        x.href === "/"
          ? location.pathname === "/"
          : location.pathname.startsWith(x.href);
      return { ...x, current };
    });
  }

  if (userUserInfoQuery.isError) {
    throw userUserInfoQuery.error;
  }

  return undefined;
};
