import {
  ChronoUnit,
  DateTimeFormatter,
  DayOfWeek,
  IsoFields,
  LocalDate,
  TemporalAdjusters,
} from "@js-joda/core";
import WEEK_OF_WEEK_BASED_YEAR = IsoFields.WEEK_OF_WEEK_BASED_YEAR;

// These functions are adapter implementations for date-fns functions that have
// been used in front-end code. That allowed a minimal change set to be created
// when switching from Date to js-joda LocalDate, but there is no other reason
// for these functions to exist.
export const getWeek = (date: LocalDate): number =>
  date.get(WEEK_OF_WEEK_BASED_YEAR);

export const isSameWeek = (left: LocalDate, right: LocalDate): boolean =>
  getWeek(left) === getWeek(right);

export const startOfWeek = (date: LocalDate): LocalDate =>
  date.with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY));

export const endOfWeek = (date: LocalDate): LocalDate =>
  date.with(TemporalAdjusters.nextOrSame(DayOfWeek.SUNDAY));

export const isSameMonth = (left: LocalDate, right: LocalDate): boolean =>
  left.monthValue() === right.monthValue();

export const addMonths = (date: LocalDate, months: number): LocalDate =>
  date.plusMonths(months);

export const startOfMonth = (date: LocalDate): LocalDate =>
  date.with(TemporalAdjusters.firstDayOfMonth());

export const endOfMonth = (date: LocalDate): LocalDate =>
  date.with(TemporalAdjusters.lastDayOfMonth());

export const getMonth = (date: LocalDate): number => date.monthValue();
export const getYear = (date: LocalDate): number => date.year();

export const startOfYear = (date: LocalDate): LocalDate =>
  date.with(TemporalAdjusters.firstDayOfYear());

export const endOfYear = (date: LocalDate): LocalDate =>
  date.with(TemporalAdjusters.lastDayOfYear());

export const addYears = (date: LocalDate, years: number): LocalDate =>
  date.plusYears(years);

export interface Interval {
  start: LocalDate;
  end: LocalDate;
}

export const eachDayOfInterval = (interval: Interval): LocalDate[] => {
  let days: LocalDate[] = [];
  let day = interval.start;
  while (day.isBefore(interval.end.plusDays(1))) {
    days.push(day);
    day = day.plusDays(1);
  }

  return days;
};

export const eachWeekOfInterval = (interval: Interval): LocalDate[] => {
  let days: LocalDate[] = [];
  let day = startOfWeek(interval.start);
  while (day.isBefore(startOfWeek(interval.end.plusWeeks(1)))) {
    days.push(day);
    day = day.plusWeeks(1);
  }

  return days;
};

export const getDaysInMonth = (date: LocalDate): number => date.lengthOfMonth();

export const addDays = (date: LocalDate, days: number): LocalDate =>
  date.plusDays(days);

export const isWithinInterval = (
  date: LocalDate,
  interval: Interval,
): boolean => !date.isBefore(interval.start) && !date.isAfter(interval.end);

export const min = (days: LocalDate[]): LocalDate =>
  days.reduce(
    (accumulator: LocalDate, currentValue: LocalDate) =>
      accumulator.isBefore(currentValue) ? accumulator : currentValue,
    days[0],
  );

export const max = (days: LocalDate[]): LocalDate =>
  days.reduce(
    (accumulator: LocalDate, currentValue: LocalDate) =>
      accumulator.isAfter(currentValue) ? accumulator : currentValue,
    days[0],
  );

export const differenceInDays = (left: LocalDate, right: LocalDate): number =>
  right.until(left, ChronoUnit.DAYS);

export const getDay = (date: LocalDate): number => date.dayOfWeek().value();

export const getDate = (date: LocalDate): number => date.dayOfMonth();

export const format = (date: LocalDate, format: string) =>
  date.format(DateTimeFormatter.ofPattern(format));

export const isWeekend = (date: LocalDate) =>
  date.dayOfWeek() == DayOfWeek.SATURDAY ||
  date.dayOfWeek() == DayOfWeek.SUNDAY;
