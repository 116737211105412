import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CreateFlexAdjustmentDto,
  CreateHolidayAdjustmentDto,
  UsersClient,
  UserWorktimeSettingsDto,
} from "../util/apiClient";

export const usersQueryKeys = {
  list: ["list"],
  getFlexAdjustments: ["UsersGetFlexAdjustments"],
  getFlexAdjustmentsWithUser: (userId: number) => [
    ...usersQueryKeys.getFlexAdjustments,
    userId,
  ],
  getHolidayAdjustments: ["UsersGetHolidayAdjustments"],
  getHolidayAdjustmentsWithUser: (userId: number) => [
    ...usersQueryKeys.getHolidayAdjustments,
    userId,
  ],
  getSupervisors: ["UsersGetSupervisors"],
  getSupervisorsWithUser: (userId: number) => [
    ...usersQueryKeys.getSupervisors,
    userId,
  ],
  getFlexHistory: ["UsersGetFlexHistory"],
  getFlexHistoryWithUser: (userId: number) => [
    ...usersQueryKeys.getFlexHistory,
    userId,
  ],
} as const;

const client = new UsersClient();

export interface IUsersAddFlexAdjustmentMutationParameters {
  userId: number;
  flexAdjustment: CreateFlexAdjustmentDto;
}

export interface IUsersAddHolidayAdjustmentMutationParameters {
  userId: number;
  holidayAdjustment: CreateHolidayAdjustmentDto;
}

export interface IUsersAddSupervisorMutationParameters {
  userId: number;
  supervisorId: number;
}

export interface IUsersRemoveSupervisorMutationParameters {
  userId: number;
  supervisorId: number;
}

export interface IUsersUpdateWorktimeSettingsMutationParameters {
  userId: number;
  worktimeSettings: UserWorktimeSettingsDto;
}

export const useUsersListQuery = () =>
  useQuery({
    queryKey: usersQueryKeys.list,
    queryFn: async () => client.list(),
  });

export const useUsersGetFlexAdjustmentsQuery = (userId: number) =>
  useQuery({
    queryKey: usersQueryKeys.getFlexAdjustmentsWithUser(userId),
    queryFn: async () => client.getFlexAdjustments(userId),
  });

export const useUsersGetHolidayAdjustmentsQuery = (userId: number) =>
  useQuery({
    queryKey: usersQueryKeys.getHolidayAdjustmentsWithUser(userId),
    queryFn: async () => client.getHolidayAdjustments(userId),
  });

export const useUsersGetSupervisorsQuery = (userId: number) =>
  useQuery({
    queryKey: usersQueryKeys.getSupervisorsWithUser(userId),
    queryFn: async () => client.getSupervisors(userId),
  });

export const useUsersGetFlexHistoryQuery = (userId: number) =>
  useQuery({
    queryKey: usersQueryKeys.getFlexHistoryWithUser(userId),
    queryFn: async () => client.getFlexHistory(userId),
  });

export const useUsersAddFlexAdjustmentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (parameters: IUsersAddFlexAdjustmentMutationParameters) =>
      client.addFlexAdjustment(parameters.userId, parameters.flexAdjustment),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: usersQueryKeys.getFlexAdjustments,
        }),
      ]),
  });
};

export const useUsersAddHolidayAdjustmentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      parameters: IUsersAddHolidayAdjustmentMutationParameters,
    ) =>
      client.addHolidayAdjustment(
        parameters.userId,
        parameters.holidayAdjustment,
      ),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: usersQueryKeys.getHolidayAdjustments,
        }),
      ]),
  });
};

export const useUsersAddSupervisorMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (parameters: IUsersAddSupervisorMutationParameters) =>
      client.addSupervisor(parameters.userId, parameters.supervisorId),
    onSettled: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: usersQueryKeys.getSupervisors,
        }),
      ]),
  });
};

export const useUsersRemoveSupervisorMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (parameters: IUsersRemoveSupervisorMutationParameters) =>
      client.removeSupervisor(parameters.userId, parameters.supervisorId),
    onSettled: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: usersQueryKeys.getSupervisors,
        }),
      ]),
  });
};

export const useUsersUpdateWorktimeSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      parameters: IUsersUpdateWorktimeSettingsMutationParameters,
    ) =>
      client.updateWorktimeSettings(
        parameters.userId,
        parameters.worktimeSettings,
      ),
    onSettled: async () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: usersQueryKeys.list,
        }),
      ]),
  });
};
