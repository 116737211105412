import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import EntryPage from "./sites/entries";
import ReportsPage from "./sites/reports";
import "./localization/i18nInit";
import DebugPanel from "./components/debug/debugPanel";
import ErrorBoundary from "./components/errorBoundary";
import VersionCheck from "./components/versionCheck";
import EnsureLogin from "./components/ensureLogin";
import UsersPage from "./sites/users";
const PublicHolidaySettings = lazy(
  () => import("./sites/settings/publicHolidaySettings"),
);
const DashboardPage = lazy(() => import("./sites/dashboard"));
const ProjectsPage = lazy(() => import("./sites/projects"));
const UserSettings = lazy(() => import("./sites/users/userSettings"));
const ProjectTasksPage = lazy(() => import("./sites/projects/projectTasks"));
const OrganizationPage = lazy(() => import("./sites/settings"));
const WorktimeSettings = lazy(
  () => import("./sites/settings/worktimeSettings"),
);
const UserSettingsFlex = lazy(() => import("./sites/users/worktime"));
const UserSettingsApproval = lazy(() => import("./sites/users/approval"));
const UserTaskSettings = lazy(() => import("./sites/users/userTaskSettings"));
const UserFlexAdjustmentSettings = lazy(
  () => import("./sites/users/flexAdjustment"),
);
const UserHolidayAdjustmentSettings = lazy(
  () => import("./sites/users/holidayAdjustment"),
);

function App() {
  return (
    <EnsureLogin>
      <VersionCheck />
      <Layout>
        <Suspense fallback={<div />}>
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/entry" element={<EntryPage />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/users/:userId" element={<UserSettings />}>
                <Route path="" element={<UserSettingsFlex />} />
                <Route path="approval" element={<UserSettingsApproval />} />
                <Route path="tasks" element={<UserTaskSettings />} />
                <Route path="flex" element={<UserFlexAdjustmentSettings />} />
                <Route
                  path="holiday"
                  element={<UserHolidayAdjustmentSettings />}
                />
              </Route>
              <Route path="/projects" element={<ProjectsPage />} />
              <Route
                path="/projects/:projectId/tasks"
                element={<ProjectTasksPage />}
              />
              <Route path="/reports" element={<ReportsPage />} />
              <Route path="/organization" element={<OrganizationPage />}>
                <Route path="" element={<WorktimeSettings />} />
                <Route path="holidays" element={<PublicHolidaySettings />} />
              </Route>
            </Routes>
          </ErrorBoundary>
        </Suspense>
        <DebugPanel />
      </Layout>
    </EnsureLogin>
  );
}

export default App;
