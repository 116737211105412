type Environment = "prod" | "dev" | "local";

const environment: Environment = (() => {
  const host = window.location.host.toLocaleLowerCase().split(":")[0];

  // "azure-prod" domain can be removed from here after migrating to "hours" domain.
  // In the meanwhile, it is needed, because otherwise wrong configuration gets
  // selected in production version.
  if (host === "azure-prod.dataops.fi" || host === "hours.dataops.fi") {
    return "prod";
  }

  if (host === "localhost" || host === "127.0.0.1") {
    return "local";
  }

  return "dev";
})();

type EnvironmentConfiguration = {
  enableVersionCheck: boolean;
  showDebugWindow?: boolean;
  showTestEnvironmentWarning?: boolean;
  baseUrl: string;
  showDetailedError: boolean;
  appInsightsConnectionString?: string;
};

const environments: { [env in Environment]: EnvironmentConfiguration } = {
  local: {
    baseUrl: "",
    showDebugWindow: true,
    showTestEnvironmentWarning: true,
    enableVersionCheck: false,
    showDetailedError: true,
  },
  dev: {
    baseUrl: "",
    appInsightsConnectionString:
      "InstrumentationKey=e604327f-c955-4f2f-a5d4-3fb337bfb035;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/",
    showDebugWindow: true,
    showTestEnvironmentWarning: true,
    enableVersionCheck: true,
    showDetailedError: true,
  },
  prod: {
    baseUrl: "",
    enableVersionCheck: true,
    appInsightsConnectionString:
      "InstrumentationKey=9515fdcb-b5df-4309-a805-8c7d560925ea;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/",
    showDetailedError: false,
  },
};

export default {
  environment,
  ...environments[environment],
};
