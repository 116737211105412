import React, {
  Component,
  ErrorInfo,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../applicationInsight/applicationInsight";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";

interface IErrorBoundaryProps extends React.PropsWithChildren {
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  sessionId: string | null;
  errorUuid: string | null;
}

class ErrorBoundaryInner extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      sessionId: null,
      errorUuid: null,
    };
  }

  public static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    const errorUuid = uuidv4();
    return {
      hasError: true,
      error,
      sessionId: appInsights?.context.getSessionId() ?? null,
      errorUuid,
    };
  }

  componentDidUpdate(
    prevProps: IErrorBoundaryProps,
    _previousState: IErrorBoundaryState,
  ) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.props.setHasError(true);
    appInsights?.trackException({
      exception: error,
      properties: {
        errorId: this.state.errorUuid,
        componentStack: errorInfo.componentStack,
        buildVersion: "__VERSION__",
      },
      severityLevel: SeverityLevel.Error,
    });
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col h-full justify-center items-center">
          <h1 className="text-lg">Something went wrong :(</h1>
          <p className="text-xs text-slate-700">
            Error identifier: {this.state.sessionId ?? 0}/{this.state.errorUuid}
          </p>
          <p className="text-xs text-red-500">
            Error message: {this.state.error?.message}
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundary: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);

  return (
    <ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundaryInner>
  );
};

export default ErrorBoundary;
