import { useQuery } from "@tanstack/react-query";
import { UserClient } from "../util/apiClient";

export const userQueryKeys = {
  loggedIn: ["UserLoggedInQuery"],
  userInfo: ["UserUserInfoQuery"],
  balances: ["UserBalancesQuery"],
} as const;

const client = new UserClient();

export const useUserLoggedInQuery = () =>
  useQuery({
    queryKey: userQueryKeys.loggedIn,
    queryFn: async () => client.loggedIn(),
  });

export const useUserUserInfoQuery = () =>
  useQuery({
    queryKey: userQueryKeys.userInfo,
    queryFn: async () => client.userInfo(),
  });

export const useUserBalancesQuery = () =>
  useQuery({
    queryKey: userQueryKeys.balances,
    queryFn: async () => client.balances(),
  });
