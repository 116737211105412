import { EntryDto, IEntryDto } from "../../util/apiClient";
import { LocalDate } from "@js-joda/core";
import { timeStringToNumber } from "./timeString";

/** Allowed values for indexing cell values */
export type TimeOrDescription = "time" | "description";

/** Data for a cell. */
export interface IEntryCell {
  /** Entry ID from the server */
  id: number;

  /** Date the hour entry is for */
  date: LocalDate;

  /** Task ID the entry belongs to */
  taskId: number;

  /** Map of values for the cell, i.e., time and description. These need to be
   * accessed dynamically, so we need a Map to keep things somewhat type safe. */
  values: Map<TimeOrDescription, string>;
}

export interface EntryCell extends IEntryCell {}

/**
 * Helper class that encapsulates entry cell operations.
 */
export class EntryCell {
  constructor(entryCell: IEntryCell) {
    Object.assign(this, entryCell);
  }

  /**
   * Create a new EntryCell from EntryDto.
   *
   * @param entryDto source EntryDto for EntryCell data
   */
  static of(entryDto: IEntryDto): EntryCell {
    return new EntryCell({
      id: entryDto.id,
      date: LocalDate.from(entryDto.date),
      taskId: entryDto.taskId,
      values: new Map([
        ["time", entryDto.time.toString()],
        ["description", entryDto.description],
      ]),
    });
  }

  /**
   * Create a new EntryDto from this EntryCell
   */
  public toEntryDto(): EntryDto {
    return new EntryDto({
      id: this.id,
      date: LocalDate.from(this.date),
      taskId: this.taskId,
      time: timeStringToNumber(this.getValue("time")),
      description: this.getValue("description"),
    });
  }

  /**
   * Get a value of a specified field from the cell
   *
   * @param valueField data field to return
   */
  public getValue(valueField: TimeOrDescription): string {
    return this.values.get(valueField) ?? "";
  }

  /**
   * Set a value for a specified cell field
   *
   * @param valueField data field to set
   * @param value value to set to data field
   */
  public setValue(valueField: TimeOrDescription, value: string): void {
    this.values.set(valueField, value);
  }
}

/**
 * Entry row - surprisingly - represents values for a one row in a table.
 *
 * The form of the object is dictated by DataTable component. For all columns
 * in the table, there must be a property in IEntryRow that has the same name
 * as the column field name. In our case, IEntryRow could contain:
 * {
 *   entries: {
 *     "2024-03-02": {
 *         id: <entry cell id>,
 *         values: {
 *             time: 2,
 *             description: "Something funny here"
 *         }
 *     "2023-03-03": {...}
 *     },
 *     ...
 *   },
 *   entryField: "time",
 *   taskId: <some task ID>
 * }
 *
 * Column field names are like "entries.2024-02-03" so cell values for columns
 * come from that part of the object. Entry field tells which value from cell
 * values property the row shows.
 */
export interface IEntryRow {
  /** Entries for the row indexable by date. These are accessed dynamically and
   * using more type safe declaration is not possible. */
  entries: { [key: string]: EntryCell };

  /** Entry cell field name this row is for, i.e., "time" or "description" */
  entryField: TimeOrDescription;

  /** Task ID if the project task this row is showing */
  taskId: number;
}

export interface EntryRow extends IEntryRow {}

/** Helper class that encapsulates IEntryRow operations */
export class EntryRow {
  constructor(entryRow: IEntryRow) {
    Object.assign(this, entryRow);
  }

  /**
   * Get cell from specified column
   *
   * @param columnField column field name, like "entries.2024-03-02"
   */
  public getCell(columnField: string): EntryCell {
    return this.entries[columnField.split(".")[1]];
  }

  /**
   * Set cell for a column specified by cell date
   *
   * @param entryCell cell to set for a column
   */
  public setCell(entryCell: EntryCell): EntryRow {
    this.entries[entryCell.date.toString()] = entryCell;
    return this;
  }

  /**
   * Create a new EntryRow from given parameters
   *
   * @param entryField entry cell field the row is responsible for
   * @param taskId task ID the entries are for
   * @param entryCells entry cells for row columns
   */
  static of(
    entryField: TimeOrDescription,
    taskId: number,
    entryCells: EntryCell[],
  ): EntryRow {
    return entryCells.reduce<EntryRow>(
      (entryRow, entryCell) => entryRow.setCell(entryCell),
      new EntryRow({ entryField: entryField, taskId: taskId, entries: {} }),
    );
  }
}

/**
 * Data model for the whole table. In our case, the table contains two rows
 * per task, one for time and for description. DataTable groups the rows by
 * task ID, so they are shown together.
 */
export interface IEntryTableModel {
  entryRows: IEntryRow[];
}

export interface EntryTableModel extends IEntryTableModel {}

/** Class encapsulates helper methods for the table model */
export class EntryTableModel {
  constructor(entryTableData: IEntryTableModel) {
    Object.assign(this, entryTableData);
  }

  /**
   * Create a table model from entries
   *
   * @param entries all entries the table needs to show
   */
  static of(entries: IEntryDto[]): EntryTableModel {
    let entryRows: IEntryRow[] = [];
    const entryCells = entries.map((entryDto) => EntryCell.of(entryDto));

    Map.groupBy(entryCells, (entry) => entry.taskId).forEach(
      (taskEntryCells, taskId) => {
        const entryRow = EntryRow.of("time", taskId, taskEntryCells);

        // We want to have one row for time and another for description,
        // because having a cell editor component with more than one input
        // component in DataTable makes things super complex.
        //
        // Another thing is that our backend  does not support patching
        // entries, only full updates are possible. When the user edits time
        // value, the update must send the description as well, but that value
        // is managed by different row. To keep data in different rows in sync,
        // we use the same entry cell objects in both of them.
        //
        // Maybe in the future, we could make the backend to support patching
        // to avoid this shared cell object requirement.
        entryRows.push(new EntryRow({ ...entryRow }));
        entryRows.push(
          new EntryRow({ ...entryRow, entryField: "description" }),
        );
      },
    );

    return new EntryTableModel({ entryRows: entryRows });
  }
}
