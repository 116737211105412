import React, { useState } from "react";
import {
  DateRangeDto,
  EntryDto,
  IEntryDto,
  IProjectTaskDto,
} from "src/util/apiClient";
import TaskChooser from "./taskChooser";
import { useUserUserInfoQuery } from "../../hooks/userQueries";
import { eachDayOfInterval } from "../../util/joda-date-fns-adapter";
import {
  convert,
  DayOfWeek,
  LocalDate,
  nativeJs,
  TemporalAdjusters,
} from "@js-joda/core";
import Loading from "../../components/loading";
import {
  useEntriesDeleteForCurrentUserMutation,
  useEntriesGetRangeForCurrentUserQuery,
  useEntriesUpdateForCurrentUserMutation,
} from "../../hooks/entriesQueries";
import EntryTable from "./entryTable";
import { Calendar } from "primereact/calendar";

interface IEntryPageProps {}

/**
 * Entry page allows user to input hours for tasks and remove them as well.
 */
const EntryPage: React.FC<IEntryPageProps> = () => {
  const userUserInfoQuery = useUserUserInfoQuery();
  const entriesDeleteForCurrentUserMutation =
    useEntriesDeleteForCurrentUserMutation();
  const entriesUpdateForCurrentUserMutation =
    useEntriesUpdateForCurrentUserMutation();
  const [selectedDate, setSelectedDate] = useState<LocalDate>(LocalDate.now());

  const range = DateRangeDto.fromJS({
    start: selectedDate.with(
      TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY),
    ),
    end: selectedDate.with(TemporalAdjusters.nextOrSame(DayOfWeek.SUNDAY)),
  });

  const entriesGetRangeForCurrentUserQuery =
    useEntriesGetRangeForCurrentUserQuery(range);

  if (userUserInfoQuery.data && entriesGetRangeForCurrentUserQuery.data) {
    const projectsWithoutTasks = userUserInfoQuery.data.assignedProjects
      .map((project) => ({
        ...project,
        tasks: project.tasks.filter(
          (task) =>
            !entriesGetRangeForCurrentUserQuery.data.some(
              (entry) => entry.taskId == task.id,
            ),
        ),
      }))
      .filter((project) => project.tasks.length > 0);

    const handleRemoveTask = async (taskId: number) => {
      const entriesToDelete = entriesGetRangeForCurrentUserQuery.data
        .filter((x) => x.taskId == taskId)
        .map((x) => x.id);

      await entriesDeleteForCurrentUserMutation.mutateAsync(entriesToDelete);
    };

    const handleAddTask = async (task: IProjectTaskDto) => {
      // This weird looking stunt is needed, because front-end assumes in many
      // places that task has an entry for all days in the week. What happens
      // in here is that when a new task (row) is added, we create a zero-hour
      // entry with an empty description for all days in the currently selected
      // week.
      const newEntries = eachDayOfInterval(range)
        .map(
          (day) =>
            ({
              date: day,
              description: "",
              id: 0,
              taskId: task.id,
              time: 0,
            }) satisfies IEntryDto,
        )
        .map(EntryDto.fromJS);

      await entriesUpdateForCurrentUserMutation.mutateAsync(newEntries);
    };

    return (
      <div className="flex h-full flex-col lg:flex-row">
        <div className="bg-gray-100 flex flex-col items-center space-y-4">
          <div className="lg:hidden flex pt-4 justify-center items-center">
            <p className="pr-2 text-lg font-semibold text-slate-700">
              Select week
            </p>
            <Calendar
              showWeek
              readOnlyInput
              formatDateTime={(date) =>
                `Week ${LocalDate.from(nativeJs(date)).isoWeekOfWeekyear()}`
              }
              value={convert(selectedDate).toDate()}
              onSelect={(e) =>
                setSelectedDate(LocalDate.from(nativeJs(e.value)))
              }
              pt={{
                table: { className: "!text-sm !my-0" },
                day: { className: "!p-0" },
                weekNumber: { className: "!p-0" },
              }}
            />
          </div>
          <div className="hidden m-4 lg:flex">
            <Calendar
              inline
              dateFormat="dd.mm.yy"
              showWeek
              value={convert(selectedDate).toDate()}
              onSelect={(e) =>
                setSelectedDate(LocalDate.from(nativeJs(e.value)))
              }
              onMonthChange={(e) => {
                setSelectedDate(
                  selectedDate.withYear(e.year).withMonth(e.month),
                );
              }}
              onViewDateChange={(e) => {
                setSelectedDate(
                  selectedDate
                    .withYear(e.value.getFullYear())
                    .withMonth(e.value.getMonth() + 1),
                );
              }}
              pt={{
                table: { className: "!text-sm !my-0" },
                day: { className: "!p-0" },
                weekNumber: { className: "!p-0" },
              }}
            />
          </div>
          <TaskChooser
            addTask={handleAddTask}
            projects={projectsWithoutTasks}
          />
        </div>
        <div className="card p-fluid w-full">
          <EntryTable
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleRemoveTask={handleRemoveTask}
          />
        </div>
      </div>
    );
  }

  if (userUserInfoQuery.isError) {
    throw userUserInfoQuery.error;
  }

  if (entriesGetRangeForCurrentUserQuery.isError) {
    throw entriesGetRangeForCurrentUserQuery.error;
  }

  return <Loading />;
};
export default EntryPage;
