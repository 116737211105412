import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const bundleVersion = "__VERSION__";

interface IVersionCheckProps {}

const storageVersionKey = "APPLICATION_VERSION";

const VersionCheck: React.FC<IVersionCheckProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("refresh")) {
      setSearchParams((params) => {
        params.delete("refresh");
        return params;
      });

      return;
    }

    import("../util/applicationVersion")
      .then((x) => x.default)
      .then((version) => {
        const checkedVersion = localStorage.getItem(storageVersionKey);
        if (checkedVersion === version) {
          return;
        }

        localStorage.setItem(storageVersionKey, version);

        if (version === bundleVersion) {
          return;
        }

        const url = new URL(window.location.href);
        url.searchParams.append("refresh", version);

        window.location.href = url.href;
      });
  }, []);

  return null;
};

export default VersionCheck;
