import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SettingsClient, WorktimeSettingsDto } from "../util/apiClient";

export const settingsQueryKeys = {
  getWorktimeSettings: ["SettingsGetWorktimeSettings"],
} as const;

const client = new SettingsClient();

export const useSettingsGetWorktimeSettingsQuery = () =>
  useQuery({
    queryKey: settingsQueryKeys.getWorktimeSettings,
    queryFn: async () => client.getWorktimeSettings(),
  });

export const useSettingsUpdateWorktimeSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (worktimeSettings: WorktimeSettingsDto) =>
      client.updateWorktimeSettings(worktimeSettings),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.getWorktimeSettings,
      }),
  });
};
