import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DateRangeDto,
  IProjectDto,
  IProjectTaskDto,
} from "../../util/apiClient";
import { DayOfWeek, LocalDate, TemporalAdjusters } from "@js-joda/core";
import {
  eachDayOfInterval,
  endOfWeek,
  startOfWeek,
} from "../../util/joda-date-fns-adapter";
import { useUserUserInfoQuery } from "../../hooks/userQueries";
import Loading from "../../components/loading";
import { DataTable } from "primereact/datatable";
import {
  Column,
  ColumnBodyOptions,
  ColumnEditorOptions,
} from "primereact/column";
import { useEntriesGetRangeForCurrentUserQuery } from "../../hooks/entriesQueries";
import EntryTableColumnFooter from "./entryTableColumnFooter";
import EntryTableHeader from "./entryTableHeader";
import EntryTableCell from "./entryTableCell";
import { Button } from "primereact/button";
import {
  EntryCell,
  EntryRow,
  EntryTableModel,
  IEntryCell,
  IEntryRow,
} from "./entryTableModel";
import { useCellState } from "./cellState";

interface IEntryTableProps {
  /** Currently selected date */
  selectedDate: LocalDate;

  /** Callback for setting selected date when the user click a table cell */
  setSelectedDate: Dispatch<SetStateAction<LocalDate>>;

  /** Callback for removing a row when the user click remove button */
  handleRemoveTask: (taskId: number) => void;
}

interface IProjectProjectTaskPair {
  project: IProjectDto;
  task: IProjectTaskDto;
}

/**
 * Component encapsulates DataTable setup for showing user entries for one week.
 */
const EntryTable: React.FC<IEntryTableProps> = ({
  selectedDate,
  setSelectedDate,
  handleRemoveTask,
}) => {
  const { t } = useTranslation();
  const userUserInfoQuery = useUserUserInfoQuery();
  const cellState = useCellState();

  const range = DateRangeDto.fromJS({
    start: selectedDate.with(
      TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY),
    ),
    end: selectedDate.with(TemporalAdjusters.nextOrSame(DayOfWeek.SUNDAY)),
  });

  const entriesGetRangeForCurrentUserQuery =
    useEntriesGetRangeForCurrentUserQuery(range);

  const weekDays = eachDayOfInterval({
    start: startOfWeek(selectedDate),
    end: endOfWeek(selectedDate),
  });

  // Lookup table for getting project and project task information when task ID is known.
  const projectTasks = useMemo(() => {
    if (!userUserInfoQuery.data) {
      return undefined;
    }

    const projectTasks = new Map<number, IProjectProjectTaskPair>();
    Map.groupBy(
      userUserInfoQuery.data.assignedProjects.flatMap((project) =>
        project.tasks.map((task) => ({ project, task })),
      ),
      (x) => x.task.id,
    ).forEach((value, key) => projectTasks.set(key, value[0]));

    return projectTasks;
  }, [userUserInfoQuery.data]);

  // Model for the entry table containing all entries
  const entryTableModel = useMemo(
    () =>
      entriesGetRangeForCurrentUserQuery.data
        ? EntryTableModel.of(entriesGetRangeForCurrentUserQuery.data)
        : undefined,
    [entriesGetRangeForCurrentUserQuery.data],
  );

  if (
    userUserInfoQuery.data &&
    entriesGetRangeForCurrentUserQuery.data &&
    projectTasks &&
    entryTableModel &&
    cellState
  ) {
    // It would be cool to handle disabling of the cell in EntryTableCell
    // component, but DataTable wants to see this higher in the DOM tree and so
    // disabling input needs to be done in here.
    const cellClassName = (entryCell: IEntryCell) =>
      entryCell.date && cellState.isCellLocked(entryCell) ? "p-disabled" : "";

    const holidayTaskIds: number[] = [];
    projectTasks.forEach((value) => {
      if (value.task.name === "Holiday") {
        holidayTaskIds.push(value.task.id);
      }
    });

    return (
      <>
        <div className="card p-fluid">
          <DataTable
            emptyMessage="No tasks selected"
            groupRowsBy="taskId"
            rowGroupMode="rowspan"
            size="small"
            value={entryTableModel.entryRows}
            editMode="cell"
            header={
              <EntryTableHeader
                date={selectedDate}
                entries={entriesGetRangeForCurrentUserQuery.data}
                calculationStarted={
                  userUserInfoQuery.data.worktimeSettings.calculationStartDate
                }
                holidayTaskIds={holidayTaskIds}
              />
            }
            cellClassName={cellClassName}
          >
            <Column
              field="taskId"
              editor={undefined}
              body={(data: IEntryCell) => (
                <>
                  <div className="flex flex-row text-sm">
                    <div>
                      <Button
                        text
                        severity="danger"
                        icon="pi pi-trash"
                        onClick={() => handleRemoveTask(data.taskId)}
                      />
                    </div>
                    <div>
                      <b>{projectTasks.get(data.taskId)?.project.name}</b>
                      <br />
                      {projectTasks.get(data.taskId)?.task.name}
                    </div>
                  </div>
                </>
              )}
              footer={"Totals"}
            ></Column>
            {weekDays.map((day, index) => (
              <Column
                style={{ width: "14.285714285714286%" }}
                field={`entries.${day.toString()}`}
                key={day.dayOfWeek().name()}
                header={
                  <div className="font-normal text-base">
                    {`${t(`common.days.${index}`)} ${day.dayOfMonth()}`}
                  </div>
                }
                body={(entryRow: EntryRow, options: ColumnBodyOptions) => (
                  <EntryTableCell
                    entryField={entryRow.entryField}
                    entry={entryRow.getCell(options.field)}
                    cellState={cellState.getCellState(
                      entryRow.getCell(options.field),
                    )}
                  />
                )}
                editor={(options: ColumnEditorOptions) => {
                  const entryCell: EntryCell = options.value;
                  const entryRow: IEntryRow = options.rowData;

                  return (
                    <EntryTableCell
                      entryField={entryRow.entryField}
                      entry={entryCell}
                      editorCallback={options.editorCallback}
                      cellState={cellState.getCellState(entryCell)}
                    />
                  );
                }}
                onBeforeCellEditShow={(e) => {
                  const entryCell: IEntryCell = e.value;
                  if (cellState.isCellActive(entryCell)) {
                    setSelectedDate(entryCell.date);
                    return true;
                  }

                  e.originalEvent.preventDefault();
                  return false;
                }}
                footer={() => (
                  <EntryTableColumnFooter
                    date={day}
                    entries={entriesGetRangeForCurrentUserQuery.data.filter(
                      (entryDto) => entryDto.date.equals(day),
                    )}
                    calculationStarted={
                      userUserInfoQuery.data.worktimeSettings
                        .calculationStartDate
                    }
                    holidayTaskIds={holidayTaskIds}
                  />
                )}
              />
            ))}
          </DataTable>
        </div>
      </>
    );
  }

  if (userUserInfoQuery.isError) {
    throw userUserInfoQuery.error;
  }

  if (entriesGetRangeForCurrentUserQuery.isError) {
    throw entriesGetRangeForCurrentUserQuery.error;
  }

  return <Loading />;
};
export default EntryTable;
