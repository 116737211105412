import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { IProjectTaskDto, IProjectDto } from "src/util/apiClient";

interface ITaskChooserProjectRowProps {
  project: IProjectDto;
  selectTask: (task: IProjectTaskDto) => void;
}

const TaskChooserProjectRow: React.FC<ITaskChooserProjectRowProps> = ({
  project,
  selectTask,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <li key={project.id} className="flex flex-col divide-gray-200 divide-y">
      <div className="block hover:bg-gray-50 cursor-pointer">
        <div
          onClick={() => setOpen((e) => !e)}
          className="flex items-center px-4 py-4 sm:px-6 select-none"
        >
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="truncate">
              <div className="flex text-sm">
                <FolderIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <p className="truncate font-medium text-gray-800">
                  {project.name}
                </p>
              </div>
            </div>
          </div>
          <div className="ml-5 flex-shrink-0">
            {open ? (
              <ChevronUpIcon className="h-5 w-5 text-gray-400" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" />
            )}
          </div>
        </div>
      </div>

      {open && (
        <ul role="list" className="divide-y divide-gray-200 bg-gray-50">
          {project.tasks.map((task) => (
            <li
              key={task.id}
              className="group flex py-4 hover:bg-gray-100 px-6 items-center cursor-pointer"
              onClick={() => selectTask(task)}
            >
              <div className="flex-1">
                <p className="text-sm text-gray-600">{task.name}</p>
              </div>
              <div>
                <ChevronRightIcon className="h-5 w-5 text-gray-400 invisible group-hover:visible" />
              </div>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default TaskChooserProjectRow;
