import React from "react";
import environment from "src/util/environment";

/**
 * Component allows user to initiate login sequence interactively.
 *
 * This is meant to be used in cases where the automatic login does not work for some reason.
 */
const SignIn: React.FC = () => {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">401</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Signed out
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Authentication is required. You need to sign-in to continue.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            href={environment.baseUrl + "/account/signin"}
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign-in
          </a>
        </div>
      </div>
    </main>
  );
};

export default SignIn;
