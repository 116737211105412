import React, { useRef, useState } from "react";
import ReportContent, { IEntryRow } from "./reportContent";
import { DateRangeDto } from "src/util/apiClient";
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
} from "../../util/joda-date-fns-adapter";
import { convert, LocalDate, nativeJs } from "@js-joda/core";
import { useProjectGetAllQuery } from "../../hooks/projectQueries";
import { useUsersListQuery } from "../../hooks/usersQueries";
import Loading from "../../components/loading";
import { useReportsGetTimeAllocationReportQuery } from "../../hooks/reportsQueries";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { localeOption, PrimeReactContext } from "primereact/api";

const Index: React.FC = () => {
  const usersListQuery = useUsersListQuery();
  const [selectedRange, setSelectedRange] = useState<Nullable<(Date | null)[]>>(
    [
      convert(startOfMonth(LocalDate.now())).toDate(),
      convert(endOfMonth(LocalDate.now())).toDate(),
    ],
  );
  const primeReactContext = React.useContext(PrimeReactContext);

  // Let Calendar component to use Date objects, but internally switch to
  // LocalDate as soon as the selection changes.
  let range: DateRangeDto;
  if (!selectedRange || !selectedRange[0] || !selectedRange[1]) {
    range = DateRangeDto.fromJS({
      start: startOfMonth(LocalDate.now()),
      end: endOfMonth(LocalDate.now()),
    });
  } else {
    range = DateRangeDto.fromJS({
      start: LocalDate.from(nativeJs(selectedRange[0])),
      end: LocalDate.from(nativeJs(selectedRange[1])),
    });
  }

  const [selectedProjectId, setSelectedProjectId] = useState<number | null>();
  const [selectedUserId, setSelectedUserId] = useState<number | null>();
  const projectGetAllQuery = useProjectGetAllQuery();
  const reportsGetTimeAllocationReportQuery =
    useReportsGetTimeAllocationReportQuery(range);
  const calendarRef = useRef<Calendar>(null);

  if (
    projectGetAllQuery.data &&
    usersListQuery.data &&
    reportsGetTimeAllocationReportQuery.data
  ) {
    let filteredData: IEntryRow[] = reportsGetTimeAllocationReportQuery.data
      .filter(
        (x) =>
          (!selectedUserId || selectedUserId === x.userId) &&
          (!selectedProjectId || selectedProjectId === x.projectId),
      )
      .map((x) => ({
        ...x,
        weekday: localeOption("dayNames", primeReactContext.locale ?? "en-gb")[
          x.date.dayOfWeek().value() % 7
        ],
      }));

    return (
      <div className="flex-1 space-y-4 p-4 flex flex-col">
        <div className="flex items-center space-x-2">
          <Calendar
            ref={calendarRef}
            selectionMode="range"
            readOnlyInput
            hideOnRangeSelection
            onChange={(e) => setSelectedRange(e.value)}
            value={selectedRange}
            footerTemplate={() => (
              <div className="flex flex-col gap-4">
                <div className="flex gap-4">
                  <Button
                    className="flex flex-1"
                    label="Last month"
                    size="small"
                    onClick={() => {
                      setSelectedRange([
                        convert(
                          startOfMonth(LocalDate.now().minusMonths(1)),
                        ).toDate(),
                        convert(
                          endOfMonth(LocalDate.now().minusMonths(1)),
                        ).toDate(),
                      ]);
                      calendarRef.current?.hide();
                    }}
                  />

                  <Button
                    className="flex flex-1"
                    label="Current month"
                    size="small"
                    onClick={() => {
                      setSelectedRange([
                        convert(startOfMonth(LocalDate.now())).toDate(),
                        convert(endOfMonth(LocalDate.now())).toDate(),
                      ]);
                      calendarRef.current?.hide();
                    }}
                  />
                </div>
                <div className="flex gap-4">
                  <Button
                    className="flex flex-1"
                    label="Last year"
                    size="small"
                    onClick={() => {
                      setSelectedRange([
                        convert(
                          startOfYear(LocalDate.now().minusYears(1)),
                        ).toDate(),
                        convert(
                          endOfYear(LocalDate.now().minusYears(1)),
                        ).toDate(),
                      ]);
                      calendarRef.current?.hide();
                    }}
                  />
                  <Button
                    className="flex flex-1"
                    label="Curent year"
                    size="small"
                    onClick={() => {
                      setSelectedRange([
                        convert(startOfYear(LocalDate.now())).toDate(),
                        convert(endOfYear(LocalDate.now())).toDate(),
                      ]);
                      calendarRef.current?.hide();
                    }}
                  />
                </div>
              </div>
            )}
            pt={{
              panel: { className: "max-w-fit" },
            }}
          />

          <Dropdown
            options={projectGetAllQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            onChange={(e) => setSelectedProjectId(e.value)}
            value={selectedProjectId}
            className={"min-w-60 max-w-fit"}
          />

          <Dropdown
            options={usersListQuery.data}
            optionValue="userId"
            optionLabel="name"
            onChange={(e) => setSelectedUserId(e.value)}
            value={selectedUserId}
            className={"min-w-60 max-w-fit"}
          />

          <Button
            label="Reset"
            disabled={!selectedProjectId && !selectedUserId}
            onClick={() => {
              setSelectedProjectId(null);
              setSelectedUserId(null);
            }}
          />
        </div>

        {reportsGetTimeAllocationReportQuery.data.length ? (
          <ReportContent tableData={filteredData} />
        ) : (
          <div className="flex items-center justify-center h-screen overflow-hidden">
            <h3 className="text-xl text-slate-400 font-semibold">
              No reports yet.
            </h3>
          </div>
        )}
      </div>
    );
  }

  if (projectGetAllQuery.isError) {
    throw projectGetAllQuery.error;
  }

  if (usersListQuery.isError) {
    throw usersListQuery.error;
  }

  if (reportsGetTimeAllocationReportQuery.isError) {
    throw reportsGetTimeAllocationReportQuery.error;
  }

  return <Loading />;
};

export default Index;
