import React, { PropsWithChildren } from "react";
import environment from "src/util/environment";
import BreakpointViewer from "./breakpointViewer";

interface IDebugPanelProps extends PropsWithChildren {}

const DebugPanel: React.FC<IDebugPanelProps> = ({ children }) => {
  if (!environment.showDebugWindow) {
    return null;
  }

  return (
    <div className="fixed bottom-0 right-0">
      <div className="p-2 bg-gray-100">
        <BreakpointViewer />
      </div>
    </div>
  );
};

export default DebugPanel;
