import React from "react";
import { classNames } from "../../util/classNames";
import { DateRangeDto, IEntryDto } from "../../util/apiClient";
import { DayOfWeek, LocalDate, TemporalAdjusters } from "@js-joda/core";
import Loading from "../../components/loading";
import { useCalendarGetRangeQuery } from "../../hooks/calendarQueries";

interface IEntryTableHeaderProps {
  /** Any date from the currently selected week */
  date: LocalDate;

  /** All entries from the selected week */
  entries: IEntryDto[];

  /** First date the user has been submitting hours */
  calculationStarted: LocalDate;

  /** Task IDs for tasks that are holidays, these are excluded from Saturday
   * flex calculations */
  holidayTaskIds: number[];
}

/**
 * Component shows information like currently selected week number and total
 * hours and flex effect for that week. This is meant to be used as a header
 * of data table.
 */
const EntryTableHeader: React.FC<IEntryTableHeaderProps> = ({
  date,
  entries,
  calculationStarted,
  holidayTaskIds,
}) => {
  const range = DateRangeDto.fromJS({
    start: date.with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY)),
    end: date.with(TemporalAdjusters.nextOrSame(DayOfWeek.SUNDAY)),
  });

  const calendarGetRangeQuery = useCalendarGetRangeQuery(range);

  if (calendarGetRangeQuery.data) {
    const weeklyTotal = entries.reduce((hours, entry) => {
      if (
        entry.date.dayOfWeek() === DayOfWeek.SATURDAY &&
        holidayTaskIds.includes(entry.taskId)
      ) {
        return hours;
      }

      return hours + entry.time;
    }, 0);

    const targetHours = calendarGetRangeQuery.data
      .filter((calendarDay) => !calendarDay.date.isBefore(calculationStarted))
      .reduce((hours, calendarDay) => hours + calendarDay.target, 0);

    const weeklyFlexTotal = weeklyTotal - targetHours;

    return (
      <>
        <div className="flex justify-center text-lg font-semibold text-slate-700">
          Week {date.isoWeekOfWeekyear()}
        </div>
        <div className="text-xs flex space-x-2 justify-center font-medium">
          <div>{weeklyTotal}h</div>
          {weeklyFlexTotal !== 0 && (
            <div
              className={classNames(
                weeklyFlexTotal && weeklyFlexTotal > 0 && "text-green-600",
                weeklyFlexTotal && weeklyFlexTotal < 0 && "text-red-600",
              )}
            >
              {`${weeklyFlexTotal && weeklyFlexTotal > 0 ? "+" : ""}${weeklyFlexTotal}h`}
            </div>
          )}
        </div>
      </>
    );
  }

  if (calendarGetRangeQuery.isError) {
    throw calendarGetRangeQuery.error;
  }

  return <Loading />;
};

export default EntryTableHeader;
