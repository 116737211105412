import React from "react";
import TaskChooserProjectRow from "./taskChooserProjectRow";
import { IProjectTaskDto, IProjectDto } from "src/util/apiClient";
import { Divider } from "primereact/divider";

interface ITaskChooserProps {
  projects: IProjectDto[];
  addTask: (task: IProjectTaskDto) => void;
}

const TaskChooser: React.FC<ITaskChooserProps> = ({ addTask, projects }) => {
  return (
    <div className="w-full px-4">
      <Divider
        className="text-sm text-gray-500 m-0 mb-4"
        pt={{ content: { className: "!bg-gray-100" } }}
      >
        Tasks
      </Divider>
      {projects.length === 0 && (
        <div className="text-center italic text-gray-500 pt-6 text-sm">
          No tasks available
        </div>
      )}
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ul
          role="list"
          id="entry-task-list"
          className="divide-y divide-gray-200"
        >
          {projects.map((project) => {
            return (
              <TaskChooserProjectRow
                project={project}
                key={project.id}
                selectTask={addTask}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TaskChooser;
