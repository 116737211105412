import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import environment from "src/util/environment";

const reactPlugin = new ReactPlugin();
const appInsights = environment.appInsightsConnectionString
  ? new ApplicationInsights({
      config: {
        connectionString: environment.appInsightsConnectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    })
  : undefined;

appInsights?.loadAppInsights();

export { appInsights };
