import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import ErrorBoundary from "./components/errorBoundary";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { addLocale, PrimeReactProvider } from "primereact/api";
import Tailwind from "primereact/passthrough/tailwind";
import "primeicons/primeicons.css";

// Prime React locale files are not included by default and need to be added
// manually as needed.
//
// These files can be found from: https://github.com/primefaces/primelocale
import en_gb from "./localization/locales/en-gb.json";

const queryClient = new QueryClient();

// Set en-gb as locale, because default "en" locale has Sunday as the first
// day of the week and that is not what anyone wants ever.
addLocale("en-gb", en_gb);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <PrimeReactProvider
          value={{ unstyled: false, pt: Tailwind, locale: "en-gb" }}
        >
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </PrimeReactProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>,
);
