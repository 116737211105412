/// <reference types="vite-plugin-svgr/client" />
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../util/classNames";
import Logo from "../../assets/logo-final.svg?react";
import MiniLogo from "../../assets/path2.svg?react";
import { useTranslation } from "react-i18next";
import { useNavigationItems } from "./navItems";
import { useUserUserInfoQuery } from "../../hooks/userQueries";

interface ISidenavProps {}

const Sidenav: React.FC<ISidenavProps> = () => {
  const { t } = useTranslation();
  const userUserInfoQuery = useUserUserInfoQuery();
  const [expanded, setExpanded] = useState<boolean>(false);
  const navigationItems = useNavigationItems();

  if (userUserInfoQuery.data && navigationItems) {
    return (
      <div
        className="flex min-h-0 flex-col bg-gray-800"
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
      >
        <div className="h-full flex flex-col">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4 ">
            <div
              className={classNames(
                "flex flex-shrink-0 items-center px-4 flex-col",
              )}
              onClick={() => setExpanded(!expanded)}
            >
              <Logo
                className={classNames(
                  "fill-gray-300 w-auto h-6",
                  !expanded && "hidden",
                  "lg:inline",
                )}
              />
              <MiniLogo
                className={classNames(
                  "fill-gray-300 w-auto h-12",
                  expanded && "hidden",
                  "lg:hidden",
                )}
              />
              <div
                className={classNames(
                  "text-gray-300 text-sm mt-1",
                  !expanded && "hidden",
                  "lg:inline",
                )}
              >
                {t("navigation.logo.productName")}
              </div>
            </div>
            <nav
              className="mt-5 flex-1 space-y-1 bg-gray-800 px-2 lg:px-4"
              aria-label="Sidebar"
            >
              {navigationItems.map((item) => (
                <Link
                  key={item.name}
                  id={item.id}
                  to={item.href}
                  onClick={() => setExpanded(false)}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "group flex items-center px-2 lg:w-48 py-2 text-sm font-medium rounded-md",
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-gray-300"
                        : "text-gray-400 group-hover:text-gray-300",
                      "mr-3 flex-shrink-0 h-6 w-6",
                    )}
                    aria-hidden="true"
                  />
                  <span
                    className={classNames(
                      "flex-1",
                      !expanded && "hidden",
                      "lg:inline",
                    )}
                  >
                    {item.name}
                  </span>
                </Link>
              ))}
            </nav>
          </div>

          <div className="flex flex-shrink-0 bg-gray-700 p-4">
            <div className="flex items-center">
              <span
                className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500"
                onClick={() => setExpanded(!expanded)}
              >
                <span className="font-medium leading-none text-white">
                  {userUserInfoQuery.data.name
                    .split(" ")
                    .map((x) => x[0])
                    .join("")}
                </span>
              </span>
              <div
                className={classNames(
                  "ml-3",
                  !expanded && "hidden",
                  "lg:inline",
                )}
              >
                <p className="text-sm font-medium text-white">
                  {userUserInfoQuery.data.name}
                </p>
                <a
                  href="/account/signout"
                  className="cursor-pointer text-xs font-medium text-gray-300 group-hover:text-gray-200"
                >
                  {t("navigation.user.signOut")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (userUserInfoQuery.isError) {
    throw userUserInfoQuery.error;
  }

  return null;
};

export default Sidenav;
