// Matches with valid hours in one day: 0-23
const hours = "(?<hours>[0-9]|([0-1][0-9])|(2[0-3]))";

// Matches with valid minutes in one hour: 00-59
const minutes = "(?<minutes>([0-5]\\d?)?)";

// Matches with decimal time string that has full hours only: 0-23
const hourOnlyInput = new RegExp(`^${hours}$`);

// Matches with decimal time string that has dot or comma separator and
// fractional part: 7.5 or 7,5
const hourWithFraction = new RegExp(`^${hours}[.,]\\d{0,2}$`);

// Matches with time string that has hours and minutes separated with a
// colon: 7:30 or 07:30
const timeInput = new RegExp(`^${hours}:${minutes}$`);

/**
 * Validate the given time string
 *
 * Valid time string formats are:
 *
 * - Decimal number with dot separator: 7.25 => 07:15
 * - Decimal number with comma separator: 7,25 => 07:25
 * - Time as "hours":"minutes": => 7:25 => 07:25
 *
 *  Valid time must have hours between [0-23] and minutes between [0-59].
 *
 * @param time time string to validate
 * @return {boolean} true if the time string is valid, false otherwise
 */
export const isValidTimeString = (time: string): boolean => {
  return (
    time === "" ||
    hourOnlyInput.test(time) ||
    hourWithFraction.test(time) ||
    timeInput.test(time)
  );
};

/**
 * Convert time string to number
 *
 * @param time time string to convert
 * @return {number} time as a decimal number, e.g., "7:15" => 7.25
 */
export const timeStringToNumber = (time: string): number => {
  if (time === "") {
    return 0;
  }

  if (timeInput.test(time)) {
    const { hours, minutes } = timeInput.exec(time)?.groups!;
    return +hours + +minutes / 60;
  } else {
    return +time.replace(",", ".");
  }
};
