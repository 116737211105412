import React from "react";
import { classNames } from "../../util/classNames";
import { DateRangeDto, IEntryDto } from "../../util/apiClient";
import { DayOfWeek, LocalDate, TemporalAdjusters } from "@js-joda/core";
import Loading from "../../components/loading";
import { useCalendarGetRangeQuery } from "../../hooks/calendarQueries";

interface IEntryTableColumnFooterProps {
  date: LocalDate;
  entries: IEntryDto[];
  calculationStarted: LocalDate;
  holidayTaskIds: number[];
}

/**
 * Component shows total hours and flex effect for a single day. This is meant
 * to be used as a footer of a column in a data table.
 */
const EntryTableColumnFooter: React.FC<IEntryTableColumnFooterProps> = ({
  date,
  entries,
  calculationStarted,
  holidayTaskIds,
}) => {
  // Technically, we only need a calendar day for "date", but it makes caching
  // more efficient if we always fetch the whole week everywhere.
  const range = DateRangeDto.fromJS({
    start: date.with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY)),
    end: date.with(TemporalAdjusters.nextOrSame(DayOfWeek.SUNDAY)),
  });

  const calendarGetRangeQuery = useCalendarGetRangeQuery(range);

  if (calendarGetRangeQuery.data) {
    const totalHours = entries.reduce((hours, entry) => {
      if (
        date.dayOfWeek() === DayOfWeek.SATURDAY &&
        holidayTaskIds.includes(entry.taskId)
      ) {
        return hours;
      }
      return hours + entry.time;
    }, 0);

    let targetHours = 0;
    if (!date.isBefore(calculationStarted)) {
      targetHours =
        calendarGetRangeQuery.data.find((calendarDay) =>
          calendarDay.date.equals(date),
        )?.target ?? 0;
    }

    const flexEffect = totalHours - targetHours;

    return (
      <>
        <div
          key={date.dayOfWeek().value()}
          className="flex flex-col text-xs space-y-2 font-normal"
        >
          {(totalHours > 0 || targetHours > 0) && (
            <div className="text-center">
              {totalHours ?? 0} / {targetHours}h
            </div>
          )}
          {!!flexEffect && (
            <div
              className={classNames(
                "text-center",
                flexEffect > 0 && "text-green-600",
                flexEffect < 0 && "text-red-600",
              )}
            >
              {flexEffect > 0 && "+"}
              {flexEffect}h
            </div>
          )}
        </div>
      </>
    );
  }

  if (calendarGetRangeQuery.isError) {
    throw calendarGetRangeQuery.error;
  }

  return <Loading />;
};

export default EntryTableColumnFooter;
