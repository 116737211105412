import { ReactNode } from "react";
import { useUserLoggedInQuery } from "../hooks/userQueries";
import SignIn from "./signIn";
import environment from "../util/environment";
import Loading from "./loading";

interface IEnsureLoginProps {
  /* Children to render when user has successfully logged in */
  children?: ReactNode;
}

/**
 * Component ensures that the user has logged in before given children are rendered.
 *
 * First, the component initiates the login sequence automatically. If login succeeds, then it
 * renders the given children.
 *
 * In case of an error it renders an error page that allows the user to initiate the login sequence
 * again interactively. This is to make it explicit to the user that there was an issue in login
 * instead of just keeping redirecting automatically to login page over and over again.
 */
const EnsureLogin = ({ children }: IEnsureLoginProps) => {
  const userLoggedInQuery = useUserLoggedInQuery();

  if (userLoggedInQuery.isPending) {
    return <Loading />;
  }

  if (userLoggedInQuery.isError) {
    return <SignIn />;
  }

  if (userLoggedInQuery.isSuccess && !userLoggedInQuery.data.valueOf()) {
    window.location.href = environment.baseUrl + "/account/signin";
  }

  return <>{children}</>;
};

export default EnsureLogin;
