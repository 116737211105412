import Excel from "exceljs";
import { IEntryRow } from "./reportContent";

export const excelExport = async (data: IEntryRow[]) => {
  const workbook = new Excel.Workbook();
  workbook.created = new Date();

  const worksheet = workbook.addWorksheet("Hours");
  worksheet.columns = [
    {
      header: "Weekday",
      key: "weekday",
      width: 15,
      style: {
        alignment: { horizontal: "left", vertical: "top" },
      },
    },
    {
      header: "Date",
      key: "date",
      width: 15,
      style: {
        numFmt: "dd.mm.yyyy",
        alignment: { horizontal: "left", vertical: "top" },
      },
    },
    {
      header: "User",
      key: "userName",
      width: 25,
      style: {
        alignment: { horizontal: "left", vertical: "top" },
      },
    },
    {
      header: "Project",
      key: "projectName",
      width: 60,
      style: {
        alignment: { horizontal: "left", vertical: "top" },
      },
    },
    { header: "Task", key: "taskName", width: 30 },
    {
      header: "Amount",
      key: "time",
      width: 10,
      style: {
        numFmt: "0.00",
        alignment: { horizontal: "left", vertical: "top" },
      },
    },
    {
      header: "Description",
      key: "description",
      width: 100,
      style: {
        alignment: {
          horizontal: "left",
          vertical: "top",
          wrapText: true,
        },
      },
    },
  ];

  worksheet.addRows(
    (data ?? []).map((row) => ({
      ...row,
      // Conversion must target UTC, because exceljs uses UTC in export. Otherwise,
      // local browser timezone might make dates to flip off by one day.
      date: new Date(
        Date.UTC(
          row.date.year(),
          row.date.monthValue() - 1,
          row.date.dayOfMonth(),
        ),
      ),
    })),
  );

  const blob = new Blob([await workbook.xlsx.writeBuffer()], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "worktime_export.xlsx";
  link.click();
  URL.revokeObjectURL(link.href);
};
