import { useQuery } from "@tanstack/react-query";
import { DateRangeDto, ReportsClient } from "../util/apiClient";

export const reportsQueryKeys = {
  getTimeAllocationReport: ["ReportsGetTimeAllocationReport"],
  getTimeAllocationReportWithRange: (range: DateRangeDto) => [
    ...reportsQueryKeys.getTimeAllocationReport,
    range.start.toString(),
    range.end.toString(),
  ],
} as const;

const client = new ReportsClient();

export const useReportsGetTimeAllocationReportQuery = (range: DateRangeDto) =>
  useQuery({
    queryKey: reportsQueryKeys.getTimeAllocationReportWithRange(range),
    queryFn: async () => client.getTimeAllocationReport(range),
  });
