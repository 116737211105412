import React, { useMemo } from "react";
import { InputText } from "primereact/inputtext";
import { isValidTimeString } from "./timeString";
import { EntryCell, TimeOrDescription } from "./entryTableModel";
import { InputTextarea } from "primereact/inputtextarea";
import { CellState } from "./cellState";
import { useEntriesUpdateForCurrentUserMutation } from "../../hooks/entriesQueries";
import _ from "lodash";

interface IEntryTableTextEditorProps {
  /** Field to use from IEntryCell values as a value of the cell */
  entryField: TimeOrDescription;

  /** Entry cell that contains cell data */
  entry: EntryCell;

  /** State of the cell */
  cellState: CellState;

  /** Callback for telling the DataTable that the value of the cell has
   * changed. Undefined, if the cell is not in editing mode. */
  editorCallback?(value: any): void;
}

/**
 * Component is responsible for showing and editing single cell values.
 *
 * There are two kinds of cells that the component supports - time and text
 * values. Decision of which one to use is made based on entryField parameter.
 *
 * Cell state is visualized with icons in input controls, but this component
 * does not disable input, because DataTable wants it to happen at higher
 * level.
 */
const EntryTableCell: React.FC<IEntryTableTextEditorProps> = ({
  entryField,
  entry,
  cellState,
  editorCallback,
}) => {
  const entriesUpdateForCurrentUserMutation =
    useEntriesUpdateForCurrentUserMutation();

  const debouncedEntriesUpdateForCurrentUserMutation = useMemo(
    () => _.debounce(entriesUpdateForCurrentUserMutation.mutate, 1000),
    [entriesUpdateForCurrentUserMutation.mutate],
  );

  return (
    <div className="p-inputgroup flex-1 min-h-10">
      {cellState & CellState.Locked ? (
        <span className="p-inputgroup-addon">
          <i className="pi pi-lock"></i>
        </span>
      ) : null}

      {entryField === "time" ? (
        <InputText
          size="small"
          type="text"
          tabIndex={editorCallback ? 0 : -1}
          value={entry.values.get(entryField)}
          className={cellState & CellState.Busy ? "font-bold" : ""}
          onChange={(e) => {
            if (isValidTimeString(e.target.value)) {
              entry.values.set(entryField, e.target.value);
              editorCallback?.(entry);

              debouncedEntriesUpdateForCurrentUserMutation([
                entry.toEntryDto(),
              ]);

              return;
            }

            e.preventDefault();
          }}
          placeholder="Time like 7.5 or 7:30"
        />
      ) : (
        <InputTextarea
          tabIndex={editorCallback ? 0 : -1}
          value={entry.values.get(entryField)}
          className={
            cellState & CellState.Busy ? "font-bold text-xs" : "text-xs"
          }
          onChange={(e) => {
            entry.values.set(entryField, e.target.value);
            editorCallback?.(entry);

            debouncedEntriesUpdateForCurrentUserMutation([entry.toEntryDto()]);
          }}
          onKeyDown={(e) => {
            // To allow user to input multiple lines, Enter key must not be propagated.
            // Otherwise, DataTable closes the editor when Enter is pressed and submits
            // changes.
            if (e.key === "Enter") {
              e.stopPropagation();
            }
          }}
          placeholder="Description"
          autoResize
        />
      )}
    </div>
  );
};

export default EntryTableCell;
