import { LockClosedIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { classNames } from "../../util/classNames";

interface IBreakpointViewerProps {}

const BreakpointViewer: React.FC<IBreakpointViewerProps> = () => {
  const labelClasses = "text-2xl font-bold";
  const labelSpanClasses = "bg-white p-2";
  const containerClasses = "h-screen bg-blue-300/20 flex";

  const [lockedOpen, setLockedOpen] = useState(false);

  return (
    <div
      className="text-sm group cursor-pointer flex"
      onClick={() => setLockedOpen((x) => !x)}
    >
      <div className="block sm:hidden">none</div>
      <div className="hidden sm:block md:hidden">sm</div>
      <div className="hidden md:block lg:hidden">md</div>
      <div className="hidden lg:block xl:hidden">lg</div>
      <div className="hidden xl:block">xl</div>
      {lockedOpen && <LockClosedIcon className="w-4 h-4" />}

      <div
        className={classNames(
          "fixed left-0 top-0 right-0 bottom-0 flex group-hover:block pointer-events-none",
          !lockedOpen && "hidden",
        )}
      >
        <div
          className={containerClasses}
          style={{
            width:
              1600 +
              "px" /* arbitrary width, must be higher than the previous */,
          }}
        >
          <div className={containerClasses} style={{ width: 1280 + "px" }}>
            <div className={containerClasses} style={{ width: 1024 + "px" }}>
              <div className={containerClasses} style={{ width: 768 + "px" }}>
                <div className={containerClasses} style={{ width: 640 + "px" }}>
                  <div className={labelClasses}>
                    <span className={labelSpanClasses}>none</span>
                  </div>
                </div>
                <div className={labelClasses}>
                  <span className={labelSpanClasses}>sm</span>
                </div>
              </div>
              <div className={labelClasses}>
                <span className={labelSpanClasses}>md</span>
              </div>
            </div>
            <div className={labelClasses}>
              <span className={labelSpanClasses}>lg</span>
            </div>
          </div>
          <div className={labelClasses}>
            <span className={labelSpanClasses}>xl</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreakpointViewer;
