import React, { useState } from "react";
import { ITimeAllocationReportDto } from "src/util/apiClient";
import { LocalDate } from "@js-joda/core";
import { startOfWeek } from "../../util/joda-date-fns-adapter";
import { DataTable, DataTableRowDataArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { excelExport } from "./excelExport";

type EntryRowWeek = `Week ${string}/${number}`;

export interface IEntryRow extends ITimeAllocationReportDto {
  [key: EntryRowWeek]: number;
}

export interface IWeeklyAggregationRow extends IEntryRow {
  amount: number;
}

interface IReportContentProps {
  tableData: IEntryRow[];
}

const ReportContent: React.FC<IReportContentProps> = ({ tableData }) => {
  const [aggregateByUser, setAggregateByUser] = useState(true);
  const [showFutureWeeks, setShowFutureWeeks] = useState(true);
  const [visibleData, setVisibleData] =
    useState<DataTableRowDataArray<IEntryRow[]>>(tableData);
  const weeklyAggregation: IWeeklyAggregationRow[] = [];
  const weeklyColumns: Set<EntryRowWeek> = new Set();

  // First group all entries by user + project + task combination or project + task
  // combination if splitting by user is disabled.
  Map.groupBy(
    tableData
      .filter(
        (x) => showFutureWeeks || startOfWeek(x.date).isBefore(LocalDate.now()),
      )
      .map(
        (row): IWeeklyAggregationRow => ({
          ...row,
          amount: 0,
        }),
      )
      .sort((a, b) => a.date.compareTo(b.date)),
    (x) => `${aggregateByUser ? x.userId : ""}-${x.projectId}-${x.taskId}`,
  ).forEach((entry) =>
    weeklyAggregation.push(
      // Reduce magic adds to all weekly aggregation rows a property:
      //   - "Week <week>/<year>" that contains a sum of all entries for that week
      //   - "amount" that contains a sum over all weeks in that row
      entry.reduce(
        (accumulator, currentValue) => {
          const columnName: EntryRowWeek = `Week ${currentValue.date.isoWeekOfWeekyear().toString().padStart(2, "0")}/${currentValue.date.year()}`;
          weeklyColumns.add(columnName);

          const newValue: IWeeklyAggregationRow = {
            ...accumulator,
            amount: accumulator.amount + currentValue.time,
          };
          newValue[columnName] =
            currentValue.time + (accumulator[columnName] ?? 0);

          return newValue;
        },
        { ...entry[0] },
      ),
    ),
  );

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-2">
        <div className="flex gap-4">
          <label>
            Split by user
            <Checkbox
              className="float-left"
              checked={aggregateByUser}
              onChange={(e) => setAggregateByUser(e.checked ?? true)}
            />
          </label>
          <label>
            Show future weeks
            <Checkbox
              className="float-left"
              checked={showFutureWeeks}
              onChange={(e) => setShowFutureWeeks(e.checked ?? true)}
            />
          </label>
        </div>
        <div className="w-fit m-4">
          <DataTable
            emptyMessage="No data available"
            removableSort
            value={weeklyAggregation}
            tableClassName="text-sm"
            cellClassName={() => "text-sm text-gray-500"}
          >
            {aggregateByUser ? (
              <Column
                field="userName"
                header="User"
                footer={"Total"}
                sortable
              />
            ) : null}
            <Column
              field="projectName"
              header="Project"
              footer={aggregateByUser ? null : "Total"}
              sortable
            />
            <Column field="taskName" header="Task" sortable />
            {Array.from(weeklyColumns)
              .sort()
              .map((weekColumn) => (
                <Column
                  field={weekColumn}
                  header={weekColumn}
                  key={weekColumn}
                  sortable
                  footer={() => {
                    const value = weeklyAggregation.reduce(
                      (accumulator, currentValue) =>
                        accumulator + (currentValue[weekColumn] ?? 0),
                      0,
                    );

                    return value !== 0 ? value : null;
                  }}
                />
              ))}
            <Column
              field="amount"
              header="Amount"
              footer={
                <div>
                  {weeklyAggregation.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.amount,
                    0,
                  )}
                </div>
              }
              sortable
            />
          </DataTable>
        </div>

        <div className="w-fit m-4">
          <DataTable
            emptyMessage="No data available"
            removableSort
            value={tableData}
            tableClassName="text-sm"
            cellClassName={() => "text-sm text-gray-500"}
            onValueChange={(e) => setVisibleData(e)}
            footer={
              <Button
                size="small"
                className="min-w-full"
                label="Export"
                onClick={async () => await excelExport(visibleData)}
              />
            }
          >
            <Column field="weekday" header="Weekday" sortable />
            <Column
              field="date"
              header="Date"
              body={(e) => {
                const date = e.date as LocalDate;
                return `${date.dayOfMonth()}.${date.monthValue()}.${date.year()}`;
              }}
              footer={"Total"}
              sortFunction={(e) => {
                return e.data.toSorted(
                  (
                    a: ITimeAllocationReportDto,
                    b: ITimeAllocationReportDto,
                  ) => {
                    const result = a.date.compareTo(b.date);
                    return e.order === 1 ? result : -result;
                  },
                );
              }}
              sortable
            />
            <Column field="userName" header="User" sortable />
            <Column field="projectName" header="Project" sortable />
            <Column field="taskName" header="Task" sortable />
            <Column
              field="time"
              header="Amount"
              footer={
                <div>
                  {tableData.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.time,
                    0,
                  )}
                </div>
              }
              sortable
            />
            <Column field="description" header="Description" sortable />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default ReportContent;
