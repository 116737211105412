import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { UserDto } from "src/util/apiClient";
import { useUsersListQuery } from "../../hooks/usersQueries";
import Loading from "../../components/loading";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

interface IUsersProps {}

const Users: React.FC<IUsersProps> = () => {
  const [showDisabledUsers, setShowDisabledUsers] = useState(false);
  const navigate = useNavigate();
  const usersListQuery = useUsersListQuery();

  if (usersListQuery.data) {
    return (
      <div>
        <BreadCrumb
          className="text-sm font-medium text-gray-500"
          model={[
            {
              template: () => <Link to="/users">Users</Link>,
            },
          ]}
          home={{ icon: "pi pi-home", url: "/" }}
        />

        <div className="w-fit m-4">
          <DataTable
            emptyMessage="No users available"
            removableSort
            value={usersListQuery.data.filter(
              (x) =>
                showDisabledUsers ||
                !x.worktimeSettings.flexCalculationDisabled,
            )}
            tableClassName="text-sm"
            cellClassName={() => "text-sm text-gray-500 py-0"}
            header={
              <label className="text-sm font-normal">
                Show disabled users
                <Checkbox
                  className="float-left mr-1"
                  checked={showDisabledUsers}
                  onChange={(e) => setShowDisabledUsers(e.checked ?? true)}
                />
              </label>
            }
          >
            <Column field="name" header="Name" sortable />
            <Column field="email" header="Email" sortable />
            <Column field="flex" header="Flex" sortable />
            <Column
              className="p-0"
              body={(user: UserDto) => (
                <Button
                  className="text-xs"
                  link
                  label="Settings ->"
                  onClick={() => navigate(`/users/${user.userId}`)}
                />
              )}
            />
          </DataTable>
        </div>
      </div>
    );
  }

  if (usersListQuery.isError) {
    throw usersListQuery.error;
  }

  return <Loading />;
};

export default Users;
