import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateRangeDto, EntriesClient, EntryDto } from "../util/apiClient";

export const entriesQueryKeys = {
  getRangeForCurrentUser: ["EntriesGetRangeForCurrentUser"],
  getRangeForCurrentUserWithRange: (range: DateRangeDto) => [
    ...entriesQueryKeys.getRangeForCurrentUser,
    range.start.toString(),
    range.end.toString(),
  ],
  deleteForCurrentUser: ["EntriesDeleteForCurrentUser"],
  updateForCurrentUser: ["EntriesUpdateForCurrentUser"],
} as const;

const client = new EntriesClient();

export const useEntriesGetRangeForCurrentUserQuery = (range: DateRangeDto) =>
  useQuery({
    queryKey: entriesQueryKeys.getRangeForCurrentUserWithRange(range),
    queryFn: async () => client.getRangeForCurrentUser(range),
  });

export const useEntriesDeleteForCurrentUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: entriesQueryKeys.deleteForCurrentUser,
    mutationFn: async (entryIds: number[]) =>
      client.deleteForCurrentUer(entryIds),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      queryClient.invalidateQueries({
        queryKey: entriesQueryKeys.getRangeForCurrentUser,
      }),
  });
};

export const useEntriesUpdateForCurrentUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: entriesQueryKeys.updateForCurrentUser,
    mutationFn: async (entries: EntryDto[]) =>
      client.updateForCurrentUser(entries),
    onMutate: async (variables) =>
      queryClient.setQueriesData(
        { queryKey: entriesQueryKeys.getRangeForCurrentUser },
        (oldData: EntryDto[] | undefined): EntryDto[] | undefined =>
          oldData?.map(
            (entry): EntryDto =>
              // It is not enough to compare id alone, because all new items
              // have id set to zero initially
              entry.id === variables[0].id &&
              entry.date.equals(variables[0].date) &&
              entry.taskId === variables[0].taskId
                ? EntryDto.fromJS({ ...variables[0] })
                : entry,
          ),
      ),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () =>
      queryClient.invalidateQueries({
        queryKey: entriesQueryKeys.getRangeForCurrentUser,
      }),
  });
};
