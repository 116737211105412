import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

interface ILoadingProps {
  text?: string;
}

const Loading: React.FC<ILoadingProps> = ({ text = "Loading", ...rest }) => {
  return (
    <div className="flex-1 items-center justify-center" {...rest}>
      <div className="flex flex-col items-center space-y-2">
        <ProgressSpinner
          style={{ width: "40px", height: "40px" }}
          strokeWidth="5"
        />
        <div className="text-sm text-gray-600">{text}</div>
      </div>
    </div>
  );
};

export default Loading;
