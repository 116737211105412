import { IEntryDto } from "../../util/apiClient";
import { useMutationState } from "@tanstack/react-query";
import { entriesQueryKeys } from "../../hooks/entriesQueries";
import { IEntryCell } from "./entryTableModel";
import { useSettingsGetWorktimeSettingsQuery } from "../../hooks/settingsQueries";
import { useUserUserInfoQuery } from "../../hooks/userQueries";

/**
 * Represents state of an individual cell
 */
export enum CellState {
  /** This is mainly for internal use. Functions never return this value. */
  None = 0,

  /** Active state is set, if no the cell is not locked or busy. */
  Active = 1,

  /** Cell date is before user work time calculation has started or the cell
   * date has been locked by an admin */
  Locked = 1 << 1,

  /** Cell value mutation is in progress. */
  Busy = 1 << 2,
}

/**
 * Hook result contains functions that can be used to query cell state. Names
 * of the functions are self-explanatory.
 */
export interface ICellStateResult {
  getCellState(entryCell: IEntryCell): CellState;

  isCellActive(entryCell: IEntryCell): boolean;

  isCellLocked(entryCell: IEntryCell): boolean;

  isCellBusy(entryCell: IEntryCell): boolean;
}

/**
 * Hook encapsulates common cell state operations.
 *
 * To determine proper state of the cells, the hook requires some data from the
 * server. Before it has that data, it returns undefined.
 *
 * @return {ICellStateResult|undefined} functions for querying cell states
 * */
export const useCellState = (): ICellStateResult | undefined => {
  const worktimeSettingsQuery = useSettingsGetWorktimeSettingsQuery();
  const userInfoQuery = useUserUserInfoQuery();

  const pendingUpdateEntryIds: number[] = useMutationState({
    filters: {
      status: "pending",
      mutationKey: entriesQueryKeys.updateForCurrentUser,
    },
    select: (mutation) =>
      (mutation.state.variables as IEntryDto[]).map(
        (entry: IEntryDto) => entry.id,
      ),
  }).flatMap((entryIds) => entryIds);

  const pendingDeleteEntryIds: number[] = useMutationState({
    filters: {
      status: "pending",
      mutationKey: entriesQueryKeys.deleteForCurrentUser,
    },
    select: (mutation) =>
      (mutation.state.variables as number[]).map((entryId: number) => entryId),
  }).flatMap((entryIds) => entryIds);

  if (!worktimeSettingsQuery.data || !userInfoQuery.data) {
    return undefined;
  }

  const getCellState = (entryCell: IEntryCell): CellState => {
    const calculationStarted =
      userInfoQuery.data.worktimeSettings.calculationStartDate;
    const lockedUntil = worktimeSettingsQuery.data.entriesLockedUntil;
    let cellState = CellState.None;

    if (entryCell.date.isBefore(calculationStarted)) {
      cellState |= CellState.Locked;
    }

    if (!entryCell.date.isAfter(lockedUntil)) {
      cellState |= CellState.Locked;
    }

    if (pendingUpdateEntryIds.includes(entryCell.id)) {
      cellState |= CellState.Busy;
    }

    if (pendingDeleteEntryIds.includes(entryCell.id)) {
      cellState |= CellState.Busy;
    }

    if (cellState === CellState.None) {
      cellState = CellState.Active;
    }

    return cellState;
  };

  const isCellActive = (entryCell: IEntryCell) =>
    Boolean(getCellState(entryCell) & CellState.Active);

  const isCellLocked = (entryCell: IEntryCell) =>
    Boolean(getCellState(entryCell) & CellState.Locked);

  const isCellBusy = (entryCell: IEntryCell) =>
    Boolean(getCellState(entryCell) & CellState.Busy);

  return {
    getCellState: getCellState,
    isCellActive: isCellActive,
    isCellLocked: isCellLocked,
    isCellBusy: isCellBusy,
  };
};
