import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CalendarClient,
  DateRangeDto,
  PublicHolidayDto,
} from "../util/apiClient";

export const calendarQueryKeys = {
  getRange: ["CalendarGetRange"],
  getRangeWithRange: (range: DateRangeDto) => [
    ...calendarQueryKeys.getRange,
    range.start.toString(),
    range.end.toString(),
  ],
  getPublicHolidays: ["CalendarGetPublicHolidays"],
  getPublicHolidaysWithYear: (year: number) => [
    ...calendarQueryKeys.getRange,
    year,
  ],
} as const;

const client = new CalendarClient();

export const useCalendarGetRangeQuery = (range: DateRangeDto) =>
  useQuery({
    queryKey: calendarQueryKeys.getRangeWithRange(range),
    queryFn: async () => client.getRange(range),
  });

export const useCalendarGetPublicHolidaysQuery = (year: number) =>
  useQuery({
    queryKey: calendarQueryKeys.getPublicHolidaysWithYear(year),
    queryFn: async () => client.getPublicHolidays(year),
  });

export const useCalendarCreatePublicHolidayMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (publicHoliday: PublicHolidayDto) =>
      client.createPublicHoliday(publicHoliday),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.getPublicHolidays,
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.getRange,
      });
    },
  });
};

export const useCalendarDeletePublicHolidayMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (publicHoliday: PublicHolidayDto) =>
      client.deletePublicHoliday(publicHoliday),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.getPublicHolidays,
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.getRange,
      });
    },
  });
};

export const useCalendarResetPublicHolidaysMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (year: number) => client.resetPublicHolidays(year),
    onError: async (error) => {
      throw error;
    },
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.getPublicHolidays,
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.getRange,
      });
    },
  });
};
