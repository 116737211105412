import React from "react";
import { classNames } from "src/util/classNames";
import environment from "src/util/environment";
import MobileNavbar from "./navigation/mobileNavbar";
import Sidenav from "./navigation/sidenav";

interface ILayoutProps extends React.PropsWithChildren {}

const Layout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <div className="h-screen w-screen flex flex-col overflow-hidden">
      {environment.showTestEnvironmentWarning && (
        <>
          <div className="bg-red-400 text-center text-sm p-1 font-semibold fixed top-0 left-0 right-0 h-8 flex items-center justify-center z-50">
            TEST ENVIRONMENT
          </div>
        </>
      )}
      <div
        className={classNames(
          "sm:hidden",
          environment.showTestEnvironmentWarning && "mt-8",
        )}
      >
        <MobileNavbar />
      </div>
      <div
        className={classNames(
          "flex flex-1 overflow-hidden",
          environment.showTestEnvironmentWarning && "sm:mt-8",
        )}
      >
        <div className="hidden sm:flex">
          <Sidenav />
        </div>
        <div className="flex-1 overflow-y-auto flex flex-col">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
